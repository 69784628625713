import {
  createContext,
  ReactNode,
  useEffect,
  useReducer,
  useState,
} from 'react';
// utils
import axios from '../utils/axios';
import { generateAuthUser, isValidToken, setSession } from '../utils/jwt';
// @types
import { ActionMap, AuthState, AuthUser, JWTContextType } from '../@types/auth';
import { AuthApi, getApi } from 'src/common/apis';
import { ELocalStorageKeys } from 'src/common/enums';
import { axiosInstance } from 'src/utils';
import { toast } from 'react-hot-toast';
import { BACKEND_API } from 'src/config';
import { ChainsApi } from 'src/common/apis/chain.api';

// ----------------------------------------------------------------------

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER',
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.Login]: {
    user: AuthUser;
  };
  [Types.Logout]: undefined;
  [Types.Register]: {
    user: AuthUser;
  };
};

export type JWTActions =
  ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case 'REGISTER':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);
  const [chains, setChains] = useState([]);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const { data } = await axiosInstance.get('/admin/@me');

          getChains();

          if (!data.data) {
            toast.error(data.message || 'Something went worng!');
            localStorage.clear();
          }

          console.log('log: data', data.data);

          const user = generateAuthUser(data?.data);

          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const getChains = async () => {
    const res = await getApi({ url: ChainsApi.base });
    if (res.data) {
      setChains(res.data?.data);
    }
  };

  const login = async (token: string) => {
    await setSession(token);

    const { data: res } = await axiosInstance.get('/admin/@me');

    console.log('log: user', res);

    getChains();

    const user = generateAuthUser(res?.data);

    dispatch({
      type: Types.Login,
      payload: {
        user,
      },
    });
  };

  const validateMfa = async (token: string, email: string) => {
    let accesstoken = localStorage.getItem(ELocalStorageKeys.MFA_TOKEN);

    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios.defaults.headers.common.Authorization = `Bearer ${accesstoken}`;

    try {
      const { data } = await axios.post(`${BACKEND_API}${AuthApi.validate}`, {
        token,
        email,
      });

      if (!data || !data.data || data.status >= 400) {
        toast.error(data?.message || data?.errors || 'Something went wrong!');
        return;
      }

      toast.success('Verified, Login Success.');
      await setSession(data.data.tokens.accessToken);

      const { data: res } = await axiosInstance.get('/admin/user/users/@me');

      const user = generateAuthUser(res?.data);

      dispatch({
        type: Types.Login,
        payload: {
          user,
        },
      });
    } catch (error) {
      toast.error(error?.message || error?.errors || 'Something went wrong!');
      return error;
    }
  };

  const register = async (
    email: string,
    password: string,
    firstName: string,
    lastName: string
  ) => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: Types.Register,
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: Types.Logout });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        chains,
        method: 'jwt',
        login,
        logout,
        register,
        validateMfa,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
