import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
import { PermissionGuard } from 'src/guards/PermissionGuard';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard = pathname.includes('/dashboard') && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'verify-mfa',
          element: (
            <GuestGuard>
              <VerifyMfa />
            </GuestGuard>
          ),
        },

        //sidebar

        { path: 'login-unprotected', element: <Login /> },
      ],
    },

    { path: '', element: <Navigate to={PATH_AFTER_LOGIN} replace /> },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          element: <Navigate to={PATH_AFTER_LOGIN} replace />,
          index: true,
        },
        { path: 'analytics', element: <GeneralApp /> },

        { path: 'users', element: <Users /> },

        { path: 'users/:id', element: <UserDetails /> },

        { path: 'transactions', element: <Transactions /> },

        { path: 'transactions/:id/edit', element: <EditTransaction /> },

        { path: 'leaderboard', element: <Leaderboard /> },

        { path: 'chains', element: <Chains /> },

        { path: 'entrypass', element: <EntryPass /> },

        { path: 'questions', element: <Questions /> },

        { path: 'questions/new', element: <CreateQuestions /> },
        { path: 'questions/:id/edit', element: <CreateQuestions /> },

        { path: 'carts', element: <Carts /> },

        { path: 'products', element: <Products /> },

        { path: 'products/new', element: <CreateProducts /> },

        { path: 'products/:id/edit', element: <CreateProducts /> },

        { path: 'rewards', element: <Rewards /> },

        { path: 'points', element: <Points /> },

        { path: 'staff', element: <Staff /> },
        { path: 'staff/new', element: <CreateStaff /> },
        { path: 'staff/:id/edit', element: <CreateStaff /> },

        // -----------------------------------------------------------------------

        {
          path: 'user',
          children: [
            {
              element: <Navigate to="/dashboard/user/profile" replace />,
              index: true,
            },
            { path: 'account', element: <UserAccount /> },
          ],
        },

        { path: 'permission-denied', element: <PermissionDenied /> },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'maintenance', element: <Maintenance /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const VerifyMfa = Loadable(lazy(() => import('../pages/auth/VerifyMfa')));

// GENERAL
const GeneralApp = Loadable(
  lazy(() => import('../pages/dashboard/GeneralApp'))
);

const Transactions = Loadable(
  lazy(() => import('../pages/dashboard/transactions'))
);

const EditTransaction = Loadable(
  lazy(() => import('../pages/dashboard/transaction-edit'))
);

const Leaderboard = Loadable(
  lazy(() => import('../pages/dashboard/leaderboard'))
);
const Chains = Loadable(lazy(() => import('../pages/dashboard/chains')));

const Users = Loadable(lazy(() => import('../pages/dashboard/users')));
const UserDetails = Loadable(
  lazy(() => import('../pages/dashboard/users/user-details'))
);

const EntryPass = Loadable(lazy(() => import('../pages/dashboard/entrypass')));

const Questions = Loadable(lazy(() => import('../pages/dashboard/questions')));
const CreateQuestions = Loadable(
  lazy(() => import('../pages/dashboard/questions/CreateQuetons'))
);

const Carts = Loadable(lazy(() => import('../pages/dashboard/cart')));
const Products = Loadable(lazy(() => import('../pages/dashboard/products')));
const CreateProducts = Loadable(
  lazy(() => import('../pages/dashboard/products/CreateProduct'))
);
const Rewards = Loadable(lazy(() => import('../pages/dashboard/rewards')));
const Points = Loadable(lazy(() => import('../pages/dashboard/points')));
const Staff = Loadable(lazy(() => import('../pages/dashboard/staff')));
const CreateStaff = Loadable(
  lazy(() => import('../pages/dashboard/staff/create-staff'))
);
// USER
const UserAccount = Loadable(
  lazy(() => import('../pages/dashboard/UserAccount'))
);

// TEST RENDER PAGE BY ROLE
const PermissionDenied = Loadable(
  lazy(() => import('../pages/dashboard/PermissionDenied'))
);

// MAIN
// const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
