export enum EAPPOINTMENT_STATUS {
  PENDING_PAYMENT = 'pending_payment',
  REQUESTED = 'requested',
  RESCHEDULE_REQUESTED = 'reschedule_requested',
  SCHEDULED = 'scheduled',
  ASSIGNED = 'assigned',
  STARTED = 'started',
  REACHED = 'reached',
  MELTED = 'melted',
  VERIFIED = 'verified',
  CANCELLED = 'cancelled',
}
