import { v4 as uuid } from 'uuid';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Card, Container, Typography } from '@mui/material';
//hooks
import useResponsive from 'src/hooks/useResponsive';
// components
import Page from 'src/components/Page';
import Logo from 'src/components/Logo';
// sections
import { LoginForm } from 'src/sections/auth/login';
import { Icon } from '@iconify/react';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  background: 'linear-gradient(to bottom, #541338,#050C5A)',
  minHeight: '100vh',
  margin: 'auto',
  alignItems: 'center',
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  maxWidth: '70%',

  padding: theme.spacing(4, 8),
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled(Card)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  height: '90vh',
  justifyContent: 'center',
  padding: theme.spacing(8, 4),
  backgroundColor: theme.palette.background.default,
}));

type FormValuesProps = {
  mobile: string;
  afterSubmit?: string;
};
type VerifyOtpProp = {
  code1: string;
  code2: string;
  code3: string;
  code4: string;
  afterSubmit?: string;
};

// ----------------------------------------------------------------------

export default function Login() {
  const smUp = useResponsive('up', 'sm');
  const device_id = uuid().slice(0, 8);

  const mdUp = useResponsive('up', 'md');

  return (
    <Page title="Admin Login">
      <RootStyle>
        <Container>
          <ContentStyle>
            <Box mr={2}>
              <Box color="primary" sx={{ my: 3 }}>
                <Logo
                  sx={{ height: 150, width: 150 }}
                  height={150}
                  width={150}
                />
                <Typography variant="h5" color="primary">
                  <span style={{ fontWeight: 700, fontFamily: 'Montserrat' }}>
                    Sign in
                  </span>{' '}
                  <span style={{ fontWeight: 500, fontFamily: 'Montserrat' }}>
                    for
                  </span>{' '}
                </Typography>

                <Typography
                  color="primary.light"
                  sx={{ marginTop: 1, fontFamily: 'Montserrat' }}
                  gutterBottom
                  variant="body2"
                >
                  Use your credentials to log in to your account
                </Typography>
              </Box>

              <LoginForm />
            </Box>
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
