import toast from 'react-hot-toast';
import { axiosInstance } from 'src/utils';
import { IAPIResponse, IDeleteAPI, IGetAPI, IPatchAPI, IPostAPI } from '../interfaces';
import axios, { AxiosResponse } from 'axios';
import { ELocalStorageKeys } from '../enums';

const EmptyBadRequest = {
  errors: 'Bad Request',
  message: 'Oops something went wrong',
  status: 400,
};

// common get api
export async function getApi({
  url,
  showToast = false,
  message,
  params,
}: IGetAPI): Promise<IAPIResponse> {
  try {
    const request = await axiosInstance.get(url, { params: params });

    const response = await handleAPIReturn(request, showToast, message);
    return response;
  } catch (error) {
    return await handleAPIReturn(error, showToast, message);
  }
}

// common post api
export async function postApi({
  url,
  values,
  showToast = false,
  message,
  params,
}: IPostAPI): Promise<IAPIResponse> {
  try {
    const request = await axiosInstance.post(url, values, { params });

    const response = await handleAPIReturn(request, showToast, message);
    return response;
  } catch (error) {
    return await handleAPIReturn(error, showToast, message);
  }
}

// common delete api
export async function deleteApi({
  url,
  values,
  showToast = false,
  message,
}: IDeleteAPI): Promise<IAPIResponse> {
  try {
    const request = await axiosInstance.delete(url, values);

    const response = await handleAPIReturn(request, showToast, message);
    return response;
  } catch (error) {
    return await handleAPIReturn(error, showToast, message);
  }
}

// common patch api
export async function patchApi({
  url,
  values,
  showToast = false,
  message,
}: IPatchAPI): Promise<IAPIResponse> {
  try {
    const request = await axiosInstance.patch(url, values);

    const response = await handleAPIReturn(request, showToast, message);
    return response;
  } catch (error) {
    return await handleAPIReturn(error, showToast, message);
  }
}

export async function putApi({
  url,
  values,
  showToast = false,
  message,
}: IPatchAPI): Promise<IAPIResponse> {
  try {
    const request = await axiosInstance.put(url, values);

    const response = await handleAPIReturn(request, showToast, message);
    return response;
  } catch (error) {
    return await handleAPIReturn(error, showToast, message);
  }
}

// Handle API data to component
const handleAPIReturn = async (
  request: AxiosResponse,
  showToast: boolean,
  message: string | undefined
): Promise<IAPIResponse> => {
  try {
    const result = await request;

    if (!result && showToast) {
      toast.error(message || EmptyBadRequest.message);
      return result || EmptyBadRequest;
    }

    const { data } = result;
    if (showToast) {
      if (data?.error || data?.status >= 400)
        toast.error(message || data?.message ? data?.message : EmptyBadRequest.message);
      else toast.success(message ? message : data?.message ? data?.message : 'Success');
    }
    return data;
  } catch (error) {
    console.log(error);
    const { data } = error.response;
    if (showToast) {
      toast.error(message || data?.message ? data?.message : EmptyBadRequest.message);
    }
    return data || EmptyBadRequest;
  }
};
