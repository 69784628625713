import { ReactNode, createContext, useEffect, useState } from 'react';
import { ELocalStorageKeys } from 'src/common/enums';
import { BACKEND_API } from 'src/config';
import useAuth from 'src/hooks/useAuth';

type SocketProviderProps = {
  children: ReactNode;
};

const initialState = {
  goldSellPrice: 0,
  goldBuyPrice: 0,
};

const SocketContext = createContext(initialState);

const SocketProvider = ({ children }: SocketProviderProps) => {
  const [socketData, setSocketData] = useState(initialState);
  const { user } = useAuth();

  const AccessToken = localStorage.getItem(ELocalStorageKeys.ACCESS_TOKEN);

  const url = new URL(BACKEND_API);

  return (
    <SocketContext.Provider value={{ ...socketData }}>
      {children}
    </SocketContext.Provider>
  );
};

export { SocketProvider, SocketContext };
