// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
  stop: getIcon('icons8-stop'),
  search: getIcon('search-sort'),
  contacts: getIcon('contacts'),
  projects: getIcon('web-page-icon'),
  settings: getIcon('ic_settings'),
  notification: getIcon('notification'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'Analytics',
    items: [
      {
        id: 'allowed',
        title: 'Dashboard',
        path: PATH_DASHBOARD.analytics,
        icon: ICONS.dashboard,
      },
    ],
  },
  // Masters
  {
    subheader: 'BZ Admin',
    items: [
      {
        id: 'allowed',
        title: 'Users',
        path: PATH_DASHBOARD.users.root,
        icon: ICONS.user,
      },
      {
        id: 'allowed',
        title: 'Transactions',
        path: PATH_DASHBOARD.transactions.root,
        icon: ICONS.invoice,
      },

      {
        id: 'allowed',
        title: 'Leaderboard',
        path: PATH_DASHBOARD.leaderboard.root,
        icon: ICONS.notification,
      },

      {
        id: 'allowed',
        title: 'Chains',
        path: PATH_DASHBOARD.chains.root,
        icon: ICONS.notification,
      },

      {
        id: 'allowed',
        title: 'Entrypass',
        path: PATH_DASHBOARD.entrypass.root,
        icon: ICONS.notification,
      },

      {
        id: 'allowed',
        title: 'Questions',
        path: PATH_DASHBOARD.questions.root,
        icon: ICONS.projects,
      },

      {
        id: 'allowed',
        title: 'Carts',
        path: PATH_DASHBOARD.product.cart,
        icon: ICONS.projects,
      },

      {
        id: 'allowed',
        title: 'Products',
        path: PATH_DASHBOARD.product.products,
        icon: ICONS.projects,
      },

      {
        id: 'allowed',
        title: 'Rewards',
        path: PATH_DASHBOARD.rewards.root,
        icon: ICONS.projects,
      },

      {
        id: 'allowed',
        title: 'Points',
        path: PATH_DASHBOARD.points.root,
        icon: ICONS.blog,
      },

      {
        id: 'allowed',
        title: 'Staff',
        path: PATH_DASHBOARD.staff.root,
        icon: ICONS.user,
      },
    ],
  },
];

export default navConfig;
